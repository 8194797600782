import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Components
 */
import Property from '@components/property/property';

const Properties = ({ className }) => {
  const { state } = useGlobalState('properties');
  return (
    <Row className={classNames('mx-md-n4', className)}>
      {state.data.map((item, index) => (
        <Col className="px-md-4" lg={4} md={6} key={index}>
          <Property property={item}  />
        </Col>
      ))}
    </Row>
  )
}

export default Properties;
