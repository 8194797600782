import React from "react";
import { Container } from 'react-bootstrap';
import Helmet from 'react-helmet';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import MainContent from '@components/main-content/main-content';
import PropertyIntro from '@components/property-intro/property-intro';
import Properties from '@components/properties/properties';
import BookVideo from '@components/book-video/book-video';

const Home = () => {
  return (
    <Layout>
      <Helmet 
        bodyAttributes={{
          class: 'search-results'
        }}
      />
      <MainContent>
        <Container>
          <PropertyIntro />
          <Properties />
        </Container>
      </MainContent>
      <BookVideo />
    </Layout>
  );
}

export default Home;